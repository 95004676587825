import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

// Ad provider tags module configurations
export const LISTING_COLUMNS = [
  {
    id: 'name',
    label: 'Name',
    width: '10%',
    align: 'left',
    dataKey: 'name',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'adProviderName',
    label: 'Provider Name',
    width: '15%',
    align: 'center',
    dataKey: 'adProviderName',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'platforms',
    label: 'Platforms',
    width: '15%',
    align: 'center',
    dataKey: 'platforms',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'channelName',
    label: 'Channel Name',
    width: '10%',
    align: 'left',
    dataKey: 'channelName',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'notes',
    label: 'Notes',
    width: '10%',
    align: 'center',
    dataKey: 'notes',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'status',
    label: 'Status',
    width: '10%',
    align: 'center',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    width: '10%',
    align: 'right',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
];

export const VIEW_COLUMNS = [
  {
    id: 'name',
    label: 'Name',
    dataKey: 'name',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'adProviderName',
    label: 'Ad Provider Name',
    dataKey: 'adProviderName',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'adUrl',
    label: 'Ad Url',
    dataKey: 'adUrl',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'platforms',
    label: 'Platforms',
    width: '15%',
    align: 'center',
    dataKey: 'platforms',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'channelName',
    label: 'Channel Name',
    width: '10%',
    align: 'left',
    dataKey: 'channelName',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'notes',
    label: 'Notes',
    dataKey: 'notes',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'status',
    label: 'Status',
    dataKey: 'status',
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];
