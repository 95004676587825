import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

// Channel Packages module configurations
export const LISTING_COLUMNS = [
  {
    id: 'name',
    label: 'Name',
    width: '15%',
    align: 'left',
    dataKey: 'name',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'frequency',
    label: 'Frequency',
    width: '10%',
    align: 'center',
    dataKey: 'frequency',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'sku',
    label: 'SKU',
    width: '15%',
    align: 'center',
    dataKey: 'sku',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'price',
    label: 'Price',
    width: '15%',
    align: 'center',
    dataKey: 'price',
    sortable: true,
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'status',
    label: 'Status',
    width: '10%',
    align: 'center',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    width: '15%',
    align: 'right',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  {
    action: ACTIONS.DELETE.value,
    label: ACTIONS.DELETE.label,
  },
];

export const VIEW_COLUMNS = [
  {
    id: 'name',
    label: 'Name',
    dataKey: 'name',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'frequency',
    label: 'Frequency',
    dataKey: 'frequency',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'features',
    label: 'Features',
    dataKey: 'features',
    type: CELL_DATA_TYPES.HTML,
  },
  {
    id: 'sku',
    label: 'SKU',
    dataKey: 'sku',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'packageOrder',
    label: 'Order',
    dataKey: 'packageOrder',
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'status',
    label: 'Status',
    dataKey: 'status',
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];
