import CELL_DATA_TYPES from '../cell-types.config';

// Deployed tags module configurations
export const LISTING_COLUMNS = [
  {
    id: 'title',
    label: 'Channel',
    width: '10%',
    align: 'left',
    dataKey: 'title',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'roku',
    label: 'Roku',
    width: '10%',
    align: 'center',
    dataKey: 'roku',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
    secondaryFields: [
      {
        dataKey: 'rokuAdProvider',
        type: CELL_DATA_TYPES.TEXT,
      },
    ],
  },
  {
    id: 'fireTv',
    label: 'Fire Tv',
    width: '10%',
    align: 'center',
    dataKey: 'fireTv',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
    secondaryFields: [
      {
        dataKey: 'fireTvAdProvider',
        type: CELL_DATA_TYPES.TEXT,
      },
    ],
  },
  {
    id: 'fireMobile',
    label: 'Fire Mobile',
    width: '10%',
    align: 'center',
    dataKey: 'fireMobile',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
    secondaryFields: [
      {
        dataKey: 'fireMobileAdProvider',
        type: CELL_DATA_TYPES.TEXT,
      },
    ],
  },
  {
    id: 'androidTv',
    label: 'Android TV',
    width: '10%',
    align: 'center',
    dataKey: 'androidTv',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
    secondaryFields: [
      {
        dataKey: 'androidTvAdProvider',
        type: CELL_DATA_TYPES.TEXT,
      },
    ],
  },
  {
    id: 'androidMobile',
    label: 'Android Mobile',
    width: '10%',
    align: 'center',
    dataKey: 'androidMobile',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
    secondaryFields: [
      {
        dataKey: 'androidMobileAdProvider',
        type: CELL_DATA_TYPES.TEXT,
      },
    ],
  },
  {
    id: 'appleTv',
    label: 'Apple TV',
    width: '10%',
    align: 'center',
    dataKey: 'appleTv',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
    secondaryFields: [
      {
        dataKey: 'appleTvAdProvider',
        type: CELL_DATA_TYPES.TEXT,
      },
    ],
  },
  {
    id: 'appleMobile',
    label: 'Apple Mobile',
    width: '10%',
    align: 'center',
    dataKey: 'appleMobile',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
    secondaryFields: [
      {
        dataKey: 'appleMobileAdProvider',
        type: CELL_DATA_TYPES.TEXT,
      },
    ],
  },
  {
    id: 'web',
    label: 'Web',
    width: '10%',
    align: 'center',
    dataKey: 'web',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
    secondaryFields: [
      {
        dataKey: 'webAdProvider',
        type: CELL_DATA_TYPES.TEXT,
      },
    ],
  },
  {
    id: 'samsungTv',
    label: 'Samsung TV',
    width: '10%',
    align: 'center',
    dataKey: 'samsungTv',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
    secondaryFields: [
      {
        dataKey: 'samsungTvAdProvider',
        type: CELL_DATA_TYPES.TEXT,
      },
    ],
  },
  {
    id: 'lgTv',
    label: 'LG TV',
    width: '10%',
    align: 'center',
    dataKey: 'lgTv',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
    secondaryFields: [
      {
        dataKey: 'lgTvAdProvider',
        type: CELL_DATA_TYPES.TEXT,
      },
    ],
  },
];

export const LISTING_ACTIONS = [];
