import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  Stack,
  Button,
  Divider,
  Typography,
  TextField,
} from '@mui/material';
import Iconify from '../common/iconify.component';
import Scrollbar from '../common/scrollbar.component';
import Platforms from '../form-elements/platforms.component';

const FilterAdProviderTags = ({ values, onCancel, onSuccess, onClear }) => {
  const [filters, setFilters] = useState(values);

  const handleApplyFilters = () => {
    setTimeout(() => {
      onSuccess(filters);
    }, 250);
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ px: 1, py: 2 }}
      >
        <Typography variant="subtitle1" sx={{ ml: 1 }}>
          Filters
        </Typography>
        <IconButton onClick={onCancel}>
          <Iconify icon="eva:close-fill" />
        </IconButton>
      </Stack>

      <Divider />

      <Scrollbar
        sx={{
          height: 1,
          '& .simplebar-content': {
            height: 1,
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <form id="list-data-filters" onSubmit={(e) => e.preventDefault()}>
          <Stack sx={{ p: 2 }}>
            <TextField
              id="fl_search"
              name="fl_search"
              label="Search"
              variant="filled"
              defaultValue={filters.search}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  search: e.target.value,
                });
              }}
              sx={{ width: '100%' }}
            />
            <Platforms
              id="fl_platforms"
              name="fl_platforms"
              label="Platforms"
              defaultValue={filters.platforms}
              multiple
              onChange={(v) => {
                setFilters({
                  ...filters,
                  platforms: v,
                });
              }}
              sx={{ width: '100%', my: 1 }}
            />
          </Stack>
        </form>
      </Scrollbar>

      <Divider />

      <Stack spacing={1} sx={{ p: 2 }}>
        <Button
          fullWidth
          form="list-data-filters"
          type="submit"
          color="primary"
          variant="contained"
          onClick={handleApplyFilters}
        >
          Apply Filters
        </Button>
        <Button
          fullWidth
          type="button"
          color="secondary"
          variant="contained"
          startIcon={<Iconify icon="ic:round-clear-all" />}
          onClick={onClear}
        >
          Clear All
        </Button>
      </Stack>
    </>
  );
};

FilterAdProviderTags.propTypes = {
  values: PropTypes.shape().isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

export default FilterAdProviderTags;
