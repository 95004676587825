import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import Header from '../components/layout/header.component';

// Layout for all app pages
const AppLayout = ({ children, pageTitle, showBack, handleBack }) => {
  const appName = process.env.REACT_APP_NAME;

  return (
    <>
      <Helmet>
        <title>
          {appName} - {pageTitle}
        </title>
      </Helmet>

      <Header
        pageTitle={pageTitle}
        showBack={showBack}
        handleBack={handleBack}
      />

      {children}
    </>
  );
};

AppLayout.propTypes = {
  children: PropTypes.any.isRequired,
  pageTitle: PropTypes.string.isRequired,
  showBack: PropTypes.bool,
  handleBack: PropTypes.func,
};
AppLayout.defaultProps = {
  showBack: false,
  handleBack: () => {},
};
export default AppLayout;
