import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  TextField,
  Grid,
  Typography,
  Switch,
  FormControlLabel,
  Divider,
  Stack,
  Box,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import SnackbarInfo from '../common/snackbar-info.component';
import { updateDataById } from '../../services/apps.service';
import IapSettings from '../form-elements/iap-settings.component';
import { IAP_SETTINGS } from '../../config/const.config';
import validationRules from '../../utils/validations.util';

const AppSubscriptionSettings = ({
  channelDetail,
  type,
  handleRefreshData,
  formValueChangedCallback,
}) => {
  const {
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
  } = useForm({
    defaultValues: {
      monthlySubscriptionPrice: channelDetail.monthlySvodPrice.toString() || '',
      yearlySubscriptionPrice: channelDetail.yearlySvodPrice.toString() || '',
      legacySVODProducts: channelDetail.legacySvodPackages || '',
      hideMonthlyPlan: !!(channelDetail.hideMonthlyPlan || 0),
      iapRoku: channelDetail.rokuIapSetting || 'none',
      iapWeb: channelDetail.webIapSetting || 'none',
      iapFireTvMobile: channelDetail.firetvIapSetting || 'none',
      iapAndroidTvMobile: channelDetail.androidIapSetting || 'none',
      iapAppleTvMobile: channelDetail.appleIapSetting || 'none',
    },
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      ...data,
      type,
    };
    updateDataById(channelDetail.id, payload)
      .then(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: 'Subscription settings updated successfully.',
        });
        formValueChangedCallback(false);
        handleRefreshData();
        setFormSubmitted(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0) {
      formValueChangedCallback(true);
    } else {
      formValueChangedCallback(false);
    }
  }, [Object.keys(dirtyFields).length]);

  return (
    <>
      <Stack direction="row" justifyContent="flex-end" sx={{ py: 2.5 }}>
        <Button
          type="submit"
          form="subscription-settings-form"
          color="primary"
          variant="contained"
          disabled={formSubmitted}
        >
          Save Subscription Settings
        </Button>
      </Stack>

      <form
        id="subscription-settings-form"
        onSubmit={handleSubmit(onFormSubmit)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="monthlySubscriptionPrice"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Monthly Subscription Price"
                  type="number"
                  error={!!errors.monthlySubscriptionPrice}
                  helperText={errors?.monthlySubscriptionPrice?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="yearlySubscriptionPrice"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Yearly Subscription Price"
                  type="number"
                  error={!!errors.yearlySubscriptionPrice}
                  helperText={errors?.yearlySubscriptionPrice?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="legacySVODProducts"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Legacy SVOD Products (Comma Seperated)"
                  type="text"
                  multiline
                  minRows={5}
                  maxRows={5}
                  autoComplete="off"
                  error={!!errors.legacySVODProducts}
                  helperText={errors?.legacySVODProducts?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="hideMonthlyPlan"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Hide Monthly Plan (Removes option for new subscribers)"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Divider
              textAlign="center"
              variant="fullWidth"
              sx={{
                fontWeight: 'bold',
              }}
            >
              Payment Validation Accounts
            </Divider>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Box color="red" fontSize="small">
              Be cautious with below settings. These should be set only once per
              platform while releasing the app. Changing values post release may
              effect payment validation flow.
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="iapRoku"
              control={control}
              rules={validationRules.REQUIRED}
              render={({ field: { onChange, value } }) => (
                <IapSettings
                  id="iap_roku"
                  name="iap_roku"
                  label="Roku"
                  defaultValue={value}
                  onChange={onChange}
                  validOptions={Object.keys(IAP_SETTINGS).filter((item) =>
                    item.includes('ROKU')
                  )}
                  error={errors?.iapRoku?.message || ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="iapWeb"
              control={control}
              rules={validationRules.REQUIRED}
              render={({ field: { onChange, value } }) => (
                <IapSettings
                  id="iap_web"
                  name="iap_web"
                  label="Web"
                  defaultValue={value}
                  onChange={onChange}
                  validOptions={Object.keys(IAP_SETTINGS).filter((item) =>
                    item.includes('WEB')
                  )}
                  error={errors?.iapWeb?.message || ''}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="iapFireTvMobile"
              control={control}
              rules={validationRules.REQUIRED}
              render={({ field: { onChange, value } }) => (
                <IapSettings
                  id="iap_fire_tv_mobile"
                  name="iap_fire_tv_mobile"
                  label="Fire TV / Mobile"
                  defaultValue={value}
                  onChange={onChange}
                  validOptions={Object.keys(IAP_SETTINGS).filter((item) =>
                    item.includes('FIRETV')
                  )}
                  error={errors?.iapFireTvMobile?.message || ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="iapAndroidTvMobile"
              control={control}
              rules={validationRules.REQUIRED}
              render={({ field: { onChange, value } }) => (
                <IapSettings
                  id="iap_android_tv_mobile"
                  name="iap_android_tv_mobile"
                  label="Android TV / Mobile"
                  defaultValue={value}
                  onChange={onChange}
                  validOptions={Object.keys(IAP_SETTINGS).filter((item) =>
                    item.includes('ANDROID')
                  )}
                  error={errors?.iapAndroidTvMobile?.message || ''}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="iapAppleTvMobile"
              control={control}
              rules={validationRules.REQUIRED}
              render={({ field: { onChange, value } }) => (
                <IapSettings
                  id="iap_apple_tv_mobile"
                  name="iap_apple_tv_mobile"
                  label="Apple TV / Mobile"
                  defaultValue={value}
                  onChange={onChange}
                  validOptions={Object.keys(IAP_SETTINGS).filter((item) =>
                    item.includes('APPLE')
                  )}
                  error={errors?.iapAppleTvMobile?.message || ''}
                />
              )}
            />
          </Grid>
        </Grid>
      </form>

      <Stack direction="row" justifyContent="flex-end" sx={{ py: 2.5 }}>
        <Typography variant="body2" color="secondary">
          * indicates a required field
        </Typography>
      </Stack>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

AppSubscriptionSettings.propTypes = {
  channelDetail: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  handleRefreshData: PropTypes.func.isRequired,
  formValueChangedCallback: PropTypes.func.isRequired,
};

export default AppSubscriptionSettings;
