import React from 'react';
import { Typography, Chip, Box, Switch, Link } from '@mui/material';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import CELL_DATA_TYPES from '../../config/cell-types.config';
import { getPlatformLabel } from '../../config/platforms.config';
import { getAdminRoleLabel } from '../../config/admin-roles.config';
import { getCouponCodeApplyOnLabel } from '../../config/coupon-codes-offers.config';
import {
  formatNumber,
  formatPercent,
  formatDuration,
  formatCurrency,
} from '../../utils/numbers.util';
import { formatDate, formatTimestamp } from '../../utils/datetime.util';
import StatusLabel from './status-label.component';
import MigrationStatusLabel from './migration-status-label.component';
import PasswordLabel from './password-label.component';
import SubscriptionSku from './subscription-sku.component';
import BrdStatusLabel from './brd-status-label.component';

const CellValue = ({ type, value, color, fallbackValue }) => {
  if (type === CELL_DATA_TYPES.NUMBER) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {formatNumber(value || 0)}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.PERCENTAGE) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {formatPercent(value || 0)}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.MONEY) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {formatCurrency(value || 0)}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.DURATION) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {formatDuration(value || 0)}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.DATE) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {formatDate(value || null, 'll')}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.DATETIME) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {formatDate(value || null, 'lll')}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.TIMESTAMP) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {formatTimestamp(value || null, 'lll')}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.BOOL) {
    return (
      <Chip
        label={Number(value) === 1 ? 'Yes' : 'No'}
        color={Number(value) === 1 ? 'success' : 'error'}
        variant="filled"
        size="small"
        sx={{ fontWeight: 700 }}
      />
    );
  }
  if (type === CELL_DATA_TYPES.PLATFORM) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {getPlatformLabel(value || '')}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.STATUS) {
    return <StatusLabel value={value} />;
  }
  if (type === CELL_DATA_TYPES.MIGRATION_STATUS) {
    return <MigrationStatusLabel value={value} />;
  }
  if (type === CELL_DATA_TYPES.PASSWORD) {
    return <PasswordLabel value={value} />;
  }
  if (type === CELL_DATA_TYPES.CODE) {
    return (
      <Box
        component="span"
        sx={{
          whiteSpace: 'unset',
          wordBreak: 'break-all',
        }}
        color={color}
      >
        {value || ''}
      </Box>
    );
  }
  if (type === CELL_DATA_TYPES.ADMIN_ROLE) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {getAdminRoleLabel(value || '')}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.SUBSCRIPTION_SKU) {
    return <SubscriptionSku value={value} />;
  }
  if (type === CELL_DATA_TYPES.COUPON_APPLICABLE_ON) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {getCouponCodeApplyOnLabel(value || '')}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.BRD_STATUS) {
    return value !== null ? <BrdStatusLabel value={value} /> : '-';
  }
  if (type === CELL_DATA_TYPES.HTML) {
    return (
      <Box component="span" sx={{ display: 'block' }}>
        <Box
          dangerouslySetInnerHTML={{
            __html: value || '',
          }}
        />
      </Box>
    );
  }
  if (type === CELL_DATA_TYPES.SWITCH) {
    return (
      <Switch
        aria-label="status"
        color="primary"
        defaultChecked={!!value}
        onChange={(e) => fallbackValue(e)}
      />
    );
  }
  if (type === CELL_DATA_TYPES.LINK) {
    return (
      <Link
        to={`${value}?cache=false`}
        component={RouterLink}
        sx={{ display: 'contents', color: '#250CA6' }}
        target="_blank"
      >
        {value}
      </Link>
    );
  }

  return (
    <Typography variant="body2" component="span" color={color}>
      {value || ''}
    </Typography>
  );
};

CellValue.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.any,
  color: PropTypes.string,
  fallbackValue: PropTypes.func,
};
CellValue.defaultProps = {
  value: null,
  color: 'inherit',
  fallbackValue: () => {},
};

export default CellValue;
