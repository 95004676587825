import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

// Channels module configurations
export const LISTING_COLUMNS = [
  {
    id: 'code',
    label: 'Acronym',
    width: '10%',
    align: 'left',
    dataKey: 'code',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'title',
    label: 'Title',
    width: '25%',
    align: 'left',
    dataKey: 'title',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
    secondaryFields: [
      {
        dataKey: 'apiKey',
        type: CELL_DATA_TYPES.TEXT,
      },
    ],
  },
  {
    id: 'websiteUrl',
    label: 'Website URL',
    width: '15%',
    align: 'left',
    dataKey: 'websiteUrl',
    sortable: false,
    type: CELL_DATA_TYPES.LINK,
  },
  {
    id: 'status',
    label: 'Status',
    width: '10%',
    align: 'right',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    width: '15%',
    align: 'right',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  {
    action: ACTIONS.COPY_CHANNEL.value,
    label: ACTIONS.COPY_CHANNEL.label,
  },
  {
    action: ACTIONS.MANAGE_CONTENT.value,
    label: ACTIONS.MANAGE_CONTENT.label,
  },
];

export const VIEW_COLUMNS = [
  {
    id: 'code',
    label: 'Acronym',
    dataKey: 'code',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'title',
    label: 'Title',
    dataKey: 'title',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'apiKey',
    label: 'Api key',
    dataKey: 'apiKey',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'lastSyncedAt',
    label: 'Last Synced At',
    dataKey: 'lastSyncedAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'gaMeasurement',
    label: 'GA Measurement',
    dataKey: 'gaMeasurement',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'gtmCode',
    label: 'GTM Code',
    dataKey: 'gtmCode',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'status',
    label: 'Status',
    dataKey: 'status',
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'modifiedAt',
    label: 'Modified At',
    dataKey: 'modifiedAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];
