import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Tab, Tabs } from '@mui/material';
import AppLayout from '../../layout/app.layout';
import { viewDataById } from '../../services/apps.service';
import ROUTE_PATH from '../../config/routes.config';
import AppMetadata from '../../components/content/app-metadata.component';
import AppSubscriptionSettings from '../../components/content/app-subscription-settings.component';
import AppGUMGUMSettings from '../../components/content/app-gumgum-settings.component';
import AppVFRSettings from '../../components/content/app-vfr-settings.component';
import AppBRDSettings from '../../components/content/app-brd-settings.component';
import AppUserOnboardMessaging from '../../components/content/app-user-onboard-msg.component';
import Loading from '../../components/table-elements/loading.component';
import SnackbarInfo from '../../components/common/snackbar-info.component';
import PageNotFoundView from '../errors/not-found.view';
import ConfirmPopup from '../../components/common/confirm-popup.component';

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box marginY={3}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
};

const AppEditView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [tabValue, setTabValue] = useState('metadata');
  const [loading, setLoading] = useState(true);
  const [reloadCounter, setReloadCounter] = useState(0);
  const [showPageNotFound, setShowPageNotFound] = useState(false);
  const [channelDetail, setChannelDetail] = useState({});
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [hasFormValueChanged, setHasFormValueChanged] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const tabValueRef = useRef('');

  const formValueChangedCallback = (val) => {
    setHasFormValueChanged(val);
  };

  const handleRefreshData = () => {
    setReloadCounter(reloadCounter + 1);
  };

  const handleTabSwitchSuccess = () => {
    setTabValue(tabValueRef.current);
    setHasFormValueChanged(false);
    setShowWarning(false);
  };

  useEffect(() => {
    if (tabValue === 'metadata') {
      setActiveTab(0);
    } else if (tabValue === 'subscription') {
      setActiveTab(1);
    } else if (tabValue === 'VFR') {
      setActiveTab(2);
    } else if (tabValue === 'BRD') {
      setActiveTab(3);
    } else if (tabValue === 'GUMGUM') {
      setActiveTab(4);
    } else if (tabValue === 'userOnboardMsg') {
      setActiveTab(5);
    } else {
      setActiveTab(5);
    }
  }, [tabValue]);

  useEffect(() => {
    viewDataById(id)
      .then((res) => {
        setChannelDetail(res.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setShowPageNotFound(true);
          return;
        }
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [id, reloadCounter]);

  return Number.isNaN(Number(id)) || showPageNotFound ? (
    <PageNotFoundView />
  ) : (
    <AppLayout
      pageTitle={`Edit Channel Details - ${channelDetail.title}`}
      showBack
      handleBack={() => navigate(ROUTE_PATH.CONTENT_ALL_CHANNELS)}
    >
      <Box height="100%">
        <Tabs
          value={tabValue}
          aria-label="tabs"
          indicatorColor="primary"
          onChange={(_, v) => {
            if (hasFormValueChanged) {
              tabValueRef.current = v;
              setShowWarning(true);
            } else {
              setTabValue(v);
            }
          }}
        >
          <Tab value="metadata" label="Basic Metadata" {...a11yProps(0)} />
          <Tab
            value="subscription"
            label="Subscription Settings"
            {...a11yProps(1)}
          />
          <Tab value="VFR" label="VFR SDK Settings" {...a11yProps(2)} />
          <Tab value="BRD" label="BRD SDK Settings" {...a11yProps(3)} />
          <Tab value="GUMGUM" label="GUMGUM SSAI Settings" {...a11yProps(4)} />
          <Tab
            value="userOnboardMsg"
            label="User Onboard Messaging"
            {...a11yProps(5)}
          />
        </Tabs>

        {loading && <Loading />}

        {!loading && (
          <>
            {tabValue === 'metadata' && (
              <TabPanel value={activeTab} index={0}>
                <AppMetadata
                  channelDetail={channelDetail}
                  type={tabValue}
                  handleRefreshData={handleRefreshData}
                  formValueChangedCallback={formValueChangedCallback}
                />
              </TabPanel>
            )}
            {tabValue === 'subscription' && (
              <TabPanel value={activeTab} index={1}>
                <AppSubscriptionSettings
                  channelDetail={channelDetail}
                  type={tabValue}
                  handleRefreshData={handleRefreshData}
                  formValueChangedCallback={formValueChangedCallback}
                />
              </TabPanel>
            )}
            {tabValue === 'VFR' && (
              <TabPanel value={activeTab} index={2}>
                <AppVFRSettings
                  channelDetail={channelDetail}
                  type={tabValue}
                  handleRefreshData={handleRefreshData}
                  formValueChangedCallback={formValueChangedCallback}
                />
              </TabPanel>
            )}
            {tabValue === 'BRD' && (
              <TabPanel value={activeTab} index={3}>
                <AppBRDSettings
                  channelDetail={channelDetail}
                  type={tabValue}
                  handleRefreshData={handleRefreshData}
                  formValueChangedCallback={formValueChangedCallback}
                />
              </TabPanel>
            )}
            {tabValue === 'GUMGUM' && (
              <TabPanel value={activeTab} index={4}>
                <AppGUMGUMSettings
                  channelDetail={channelDetail}
                  type={tabValue}
                  handleRefreshData={handleRefreshData}
                  formValueChangedCallback={formValueChangedCallback}
                />
              </TabPanel>
            )}
            {tabValue === 'userOnboardMsg' && (
              <TabPanel value={activeTab} index={5}>
                <AppUserOnboardMessaging
                  channelDetail={channelDetail}
                  type={tabValue}
                  handleRefreshData={handleRefreshData}
                  formValueChangedCallback={formValueChangedCallback}
                />
              </TabPanel>
            )}
          </>
        )}
      </Box>

      {showWarning && (
        <ConfirmPopup
          title="Tab Switch Warning"
          message="Do you want to switch tab without saving data? Before switching tab, save your changes otherwise you lost all your changes and can not undo this action!"
          onCancel={() => setShowWarning(false)}
          onSuccess={() => handleTabSwitchSuccess()}
        />
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </AppLayout>
  );
};

export default AppEditView;
