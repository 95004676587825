import React, { useEffect, useState } from 'react';
import {
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableContainer,
  Paper,
  TextField,
  Button,
  Typography,
  Grid,
  TableHead,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import MANAGE_COLUMNS from '../../config/module-configs/assign-tags.config';
import AdProviderTags from '../form-elements/ad-provider-tags.component';
import Channels from '../form-elements/channels.component';
import validationRules from '../../utils/validations.util';
import {
  assignAdProviderTagsToChannel,
  getAdProviderTagsByChannel,
} from '../../services/ad-provider-tags.service';

const AssignTags = () => {
  const columns = MANAGE_COLUMNS;

  const [loading, setLoading] = useState(true);
  const [channelId, setChannelId] = useState(null);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm({});

  useEffect(() => {
    setLoading(true);
    if (channelId) {
      getAdProviderTagsByChannel(channelId)
        .then((res) => {
          Object.keys(res.data).forEach((key) => {
            setValue(`${key}.tag`, res.data[key].tag);
            setValue(`${key}.adProvider`, res.data[key]?.tag?.adProviderName);
            setValue(`${key}.privacyPolicy`, res.data[key].privacyPolicy);
            setValue(`${key}.appStoreUrl`, res.data[key].appStoreUrl);
            setValue(`${key}.bundleId`, res.data[key].bundleId);
          });
          setLoading(false);
        })
        .catch((err) => {
          const message =
            err.response?.data?.message ||
            'Something went wrong, please try again.';
          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'error',
            message,
          });
          setLoading(false);
        });
    } else {
      columns.forEach((item) => {
        setValue(`${item.id}.tag`, '');
        setValue(`${item.id}.adProvider`, '');
        setValue(`${item.id}.privacyPolicy`, '');
        setValue(`${item.id}.appStoreUrl`, '');
        setValue(`${item.id}.bundleId`, '');
      });
      // Patch for set value
      setTimeout(() => {
        setLoading(false);
      }, 50);
    }
  }, [channelId]);

  const formateData = (obj) => {
    const result = {};

    Object.entries(obj).forEach(([key, value]) => {
      const { tag, adProvider, ...rest } = value;
      result[key] = {
        ...rest,
        tag: tag ? tag.id : '',
      };
    });

    return result;
  };

  const onFormSubmit = (data) => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    const payload = formateData(data);
    assignAdProviderTagsToChannel(channelId, payload)
      .then(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: 'Tag details updated successfully.',
        });
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
      });
  };

  return (
    <>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={4} md={4}>
          <Channels
            id="user_channels"
            name="user_channels"
            label="Select Channel"
            defaultValue={channelId}
            onChange={(v) => setChannelId(v?.id || null)}
            sx={{ width: '100%' }}
            error={errors?.channels?.message || ''}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            type="submit"
            form="add-edit-form"
            color="primary"
            variant="contained"
            disabled={loading || !channelId}
          >
            Save
          </Button>
        </Grid>
      </Grid>

      <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="10%">
                  <Typography variant="subtitle2">Platform</Typography>
                </TableCell>
                <TableCell width="18%">
                  {' '}
                  <Typography variant="subtitle2" sx={{ textAlign: 'center' }}>
                    Tag
                  </Typography>
                </TableCell>
                <TableCell width="18%">
                  {' '}
                  <Typography variant="subtitle2" sx={{ textAlign: 'center' }}>
                    Ad Provider
                  </Typography>
                </TableCell>
                <TableCell width="18%">
                  {' '}
                  <Typography variant="subtitle2" sx={{ textAlign: 'center' }}>
                    Privacy Policy
                  </Typography>
                </TableCell>
                <TableCell width="18%">
                  {' '}
                  <Typography variant="subtitle2" sx={{ textAlign: 'center' }}>
                    AppStore URL
                  </Typography>
                </TableCell>
                <TableCell width="18%">
                  {' '}
                  <Typography variant="subtitle2" sx={{ textAlign: 'center' }}>
                    Bundle ID
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {loading && (
                <TableRow>
                  <TableCell width="100%" colSpan={6}>
                    <Loading />
                  </TableCell>
                </TableRow>
              )}

              {!loading &&
                columns.map((c) => (
                  <>
                    <TableRow key={`col-${c.id}`}>
                      <TableCell
                        width="10%"
                        variant="body"
                        sx={{ border: 0, verticalAlign: 'center' }}
                      >
                        {c.label}
                      </TableCell>

                      <TableCell
                        width="18%"
                        variant="body"
                        sx={{ border: 0, verticalAlign: 'top' }}
                      >
                        <Controller
                          name={`${c.dataKey}.tag`}
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value } }) => (
                            <AdProviderTags
                              id="adProviderTags"
                              name="adProviderTags"
                              label="Tag"
                              defaultValue={value}
                              onChange={(v) => {
                                onChange(v);
                                setValue(
                                  `${c.dataKey}.adProvider`,
                                  v?.adProviderName || ''
                                );
                              }}
                              sx={{ width: '100%' }}
                              error={errors?.[c.dataKey]?.tag?.message || ''}
                              disabled={!channelId}
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell
                        width="18%"
                        variant="body"
                        sx={{
                          border: 0,
                          verticalAlign: 'center',
                          textAlign: 'center',
                        }}
                      >
                        <Controller
                          name={`${c.dataKey}.adProvider`}
                          control={control}
                          defaultValue=""
                          render={() => (
                            <Typography variant="body1">
                              {watch(`${c.dataKey}.adProvider`) || '-'}
                            </Typography>
                          )}
                        />
                      </TableCell>
                      <TableCell
                        width="18%"
                        variant="body"
                        sx={{ border: 0, verticalAlign: 'top' }}
                      >
                        <Controller
                          name={`${c.dataKey}.privacyPolicy`}
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label="Privacy Policy"
                              type="text"
                              error={!!errors?.[c.dataKey]?.privacyPolicy}
                              helperText={
                                errors?.[c.dataKey]?.privacyPolicy?.message ||
                                null
                              }
                              fullWidth
                              variant="filled"
                              disabled={!channelId}
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell
                        width="18%"
                        variant="body"
                        sx={{ border: 0, verticalAlign: 'top' }}
                      >
                        <Controller
                          name={`${c.dataKey}.appStoreUrl`}
                          control={control}
                          rules={{ ...validationRules.OPTIONAL_URL }}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label="AppStore URL"
                              type="text"
                              error={!!errors?.[c.dataKey]?.appStoreUrl}
                              helperText={
                                errors?.[c.dataKey]?.appStoreUrl?.message ||
                                null
                              }
                              fullWidth
                              variant="filled"
                              disabled={!channelId}
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell
                        width="18%"
                        variant="body"
                        sx={{ border: 0, verticalAlign: 'top' }}
                      >
                        <Controller
                          name={`${c.dataKey}.bundleId`}
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label="Bundle ID"
                              type="text"
                              error={!!errors?.[c.dataKey]?.bundleId}
                              helperText={
                                errors?.[c.dataKey]?.bundleId?.message || null
                              }
                              fullWidth
                              variant="filled"
                              disabled={!channelId}
                            />
                          )}
                        />
                      </TableCell>
                    </TableRow>

                    <TableCell colSpan={6} sx={{ padding: 0 }} />
                  </>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </form>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

export default AssignTags;
