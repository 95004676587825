import React from 'react';
import AppLayout from '../../layout/app.layout';
import AssignTags from '../../components/advertisers/assign-tags.component';

const AssignTagsView = () => {
  const pageTitle = 'Advertising / Assign Tags';

  return (
    <AppLayout pageTitle={pageTitle}>
      <AssignTags />
    </AppLayout>
  );
};

export default AssignTagsView;
