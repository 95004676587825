import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Drawer, IconButton } from '@mui/material';
import useResponsive from '../../hooks/responsive.hook';
import Logo from '../common/logo.component';
import Scrollbar from '../common/scrollbar.component';
import NavItems from './sidebar/nav-items.component';
import Iconify from '../common/iconify.component';

const NAV_WIDTH = 280;

const Sidebar = ({ openNav, onOpenNav, onCloseNav }) => {
  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, []);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Box
        sx={{
          px: 2.5,
          py: 1,
          display: 'inline-flex',
          background: 'rgba(255, 255, 255, 0.15)',
        }}
      >
        <Logo
          sx={{
            height: 75,
            margin: '0px auto',
          }}
        />
      </Box>

      <NavItems />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <>
      <IconButton
        onClick={onOpenNav}
        sx={{
          display: { lg: 'none' },
          height: 'fit-content',
          position: 'absolute',
          top: 18,
          left: 25,
          mr: 1,
          color: 'text.primary',
          zIndex: 1111,
        }}
      >
        <Iconify icon="eva:menu-2-fill" />
      </IconButton>

      <Box
        component="nav"
        sx={{
          flexShrink: { lg: 0 },
          width: { lg: NAV_WIDTH },
        }}
      >
        {isDesktop ? (
          <Drawer
            open
            variant="permanent"
            PaperProps={{
              sx: {
                width: NAV_WIDTH,
                bgcolor: '#000000',
                borderRightStyle: 'dashed',
              },
            }}
          >
            {renderContent}
          </Drawer>
        ) : (
          <Drawer
            open={openNav}
            onClose={onCloseNav}
            ModalProps={{
              keepMounted: true,
            }}
            PaperProps={{
              sx: { width: NAV_WIDTH, bgcolor: '#000000' },
            }}
          >
            {renderContent}
          </Drawer>
        )}
      </Box>
    </>
  );
};

Sidebar.propTypes = {
  openNav: PropTypes.bool.isRequired,
  onOpenNav: PropTypes.func.isRequired,
  onCloseNav: PropTypes.func.isRequired,
};

export default Sidebar;
