const MANAGE_COLUMNS = [
  {
    id: 'homePageHeroImage',
    label: 'Home Page Hero Image',
    dataKey: 'homePageHeroImage',
    type: 'TEXT',
  },
  {
    id: 'browsePageBgImage',
    label: 'Browse Page Background Image',
    dataKey: 'browsePageBgImage',
    type: 'TEXT',
  },
  {
    id: 'favIconImage',
    label: 'Fav Icon Image',
    dataKey: 'favIconImage',
    type: 'TEXT',
  },
  {
    id: 'logoImage',
    label: 'Logo Image',
    dataKey: 'logoImage',
    type: 'TEXT',
  },
  {
    id: 'themeBackgroundColor',
    label: 'Theme Background Color',
    dataKey: 'themeBackgroundColor',
    type: 'TEXT',
  },
  {
    id: 'themeTextColor',
    label: 'Theme Text Color',
    dataKey: 'themeTextColor',
    type: 'TEXT',
  },
  {
    id: 'themeHeaderColor',
    label: 'Theme Header Text Color',
    dataKey: 'themeHeaderColor',
    type: 'TEXT',
  },
  {
    id: 'themePrimaryColor',
    label: 'Theme Primary Color',
    dataKey: 'themePrimaryColor',
    type: 'TEXT',
  },
  {
    id: 'themePrimaryTextColor',
    label: 'Theme Primary Text Color',
    dataKey: 'themePrimaryTextColor',
    type: 'TEXT',
  },
  {
    id: 'themeSecondaryColor',
    label: 'Theme Secondary Color',
    dataKey: 'themeSecondaryColor',
    type: 'TEXT',
  },
  {
    id: 'themeSecondaryTextColor',
    label: 'Theme Secondary Text Color',
    dataKey: 'themeSecondaryTextColor',
    type: 'TEXT',
  },
  {
    id: 'themeCardPrimaryBackgroundColor',
    label: 'Card Primary Background Color',
    dataKey: 'themeCardPrimaryBackgroundColor',
    type: 'TEXT',
  },
  {
    id: 'themeCardPrimaryTextColor',
    label: 'Card Primary Text Color',
    dataKey: 'themeCardPrimaryTextColor',
    type: 'TEXT',
  },
  {
    id: 'themeCardSecondaryBackgroundColor',
    label: 'Card Secondary Background Color',
    dataKey: 'themeCardSecondaryBackgroundColor',
    type: 'TEXT',
  },
  {
    id: 'themeCardSecondaryTextColor',
    label: 'Card Secondary Text Color',
    dataKey: 'themeCardSecondaryTextColor',
    type: 'TEXT',
  },
  {
    id: 'themeVideoProgressColor',
    label: 'Theme Video Progress Color',
    dataKey: 'themeVideoProgressColor',
    type: 'TEXT',
  },
];

export default MANAGE_COLUMNS;
