import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  TextField,
  Grid,
  Typography,
  Switch,
  FormControlLabel,
  Divider,
  Stack,
  Box,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import SnackbarInfo from '../common/snackbar-info.component';
import { updateDataById } from '../../services/apps.service';
import { maxLengthValidation } from '../../utils/validations.util';

const AppVFRSettings = ({
  channelDetail,
  type,
  handleRefreshData,
  formValueChangedCallback,
}) => {
  const {
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
  } = useForm({
    defaultValues: {
      VFRRokuLibPath: channelDetail.vfrRokuLibPath || '',
      VFRChannel: channelDetail.vfrChannel || '',
      VFRPublisher: channelDetail.vfrPublisher || '',
      useVFRInDemoMode: !!(channelDetail.isVfrForDemo || 0),
      enableVFRRoku: !!(channelDetail.rokuEnableVfrSdk || 0),
      enableVFRWeb: !!(channelDetail.webEnableVfrSdk || 0),
      enableVFRFireTv: !!(channelDetail.firetvEnableVfrSdk || 0),
      enableVFRFireMobile: !!(channelDetail.fireMobileEnableVfrSdk || 0),
      enableVFRAndroidTv: !!(channelDetail.androidtvEnableVfrSdk || 0),
      enableVFRAndroidMobile: !!(channelDetail.androidMobileEnableVfrSdk || 0),
      enableVFRAppleTv: !!(channelDetail.appletvEnableVfrSdk || 0),
      enableVFRAppleMobile: !!(channelDetail.appleMobileEnableVfrSdk || 0),
      infoPages: !!(channelDetail.allowVfrSdkInfoPages || 0),
      insidePages: !!(channelDetail.allowVfrSdkInsidePages || 0),
      videoPlayerRoku: !!(channelDetail.allowVfrSdkVideoPlayerRoku || 0),
      videoPlayerFireTv: !!(channelDetail.allowVfrSdkVideoPlayerFiretv || 0),
      videoPlayerOtherPlatforms: !!(channelDetail.allowVfrSdkVideoPlayer || 0),
      videoPlayerOnRAFAds: !!(
        channelDetail.allowVfrSdkVideoPlayerOnRafAds || 0
      ),
      enableVFRInsidePages: !!(
        channelDetail.enableVfrInsidePagesWhenBrdOn || 0
      ),
    },
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      ...data,
      type,
    };
    updateDataById(channelDetail.id, payload)
      .then(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: 'VFR settings updated successfully.',
        });
        formValueChangedCallback(false);
        handleRefreshData();
        setFormSubmitted(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0) {
      formValueChangedCallback(true);
    } else {
      formValueChangedCallback(false);
    }
  }, [Object.keys(dirtyFields).length]);

  return (
    <>
      <Stack direction="row" justifyContent="flex-end" sx={{ py: 2.5 }}>
        <Button
          type="submit"
          form="vfr-settings-form"
          color="primary"
          variant="contained"
          disabled={formSubmitted}
        >
          Save VFR Settings
        </Button>
      </Stack>

      <form id="vfr-settings-form" onSubmit={handleSubmit(onFormSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Box color="gray" fontSize="small">
              These will be used when VFR SDK is enabled. Not providing these
              values when VFR SDK is enabled may lead to unexpected behavior.
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Controller
              name="VFRRokuLibPath"
              control={control}
              rules={maxLengthValidation(255)}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="VFR Roku Lib Path"
                  type="text"
                  error={!!errors.VFRRokuLibPath}
                  helperText={errors?.VFRRokuLibPath?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="VFRChannel"
              control={control}
              rules={maxLengthValidation(100)}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="VFR Channel"
                  type="text"
                  error={!!errors.VFRChannel}
                  helperText={errors?.VFRChannel?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="VFRPublisher"
              control={control}
              rules={maxLengthValidation(100)}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="VFR Publisher"
                  type="text"
                  error={!!errors.VFRPublisher}
                  helperText={errors?.VFRPublisher?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="useVFRInDemoMode"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Use VFR In Demo Mode"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} />

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="enableVFRRoku"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Enable VFR Roku"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="enableVFRWeb"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Enable VFR Web"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="enableVFRFireTv"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Enable VFR Fire TV"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="enableVFRFireMobile"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Enable VFR Fire Mobile"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="enableVFRAndroidTv"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Enable VFR Android TV"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="enableVFRAndroidMobile"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Enable VFR Android Mobile"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="enableVFRAppleTv"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Enable VFR Apple TV"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="enableVFRAppleMobile"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Enable VFR Apple Mobile"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Divider
              textAlign="center"
              variant="fullWidth"
              sx={{
                fontWeight: 'bold',
              }}
            >
              Allow VFR SDK On
            </Divider>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="infoPages"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Info Pages"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="insidePages"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Inside Pages"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="videoPlayerRoku"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Video Player (Roku)"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="videoPlayerFireTv"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Video Player (Fire TV)"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="videoPlayerOtherPlatforms"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Video Player (Other Platforms)"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="videoPlayerOnRAFAds"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Video Player On RAF Ads"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="enableVFRInsidePages"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Enable VFR Inside Pages When BRD is ON"
                />
              )}
            />
          </Grid>
        </Grid>
      </form>

      <Stack direction="row" justifyContent="flex-end" sx={{ py: 2.5 }}>
        <Typography variant="body2" color="secondary">
          * indicates a required field
        </Typography>
      </Stack>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

AppVFRSettings.propTypes = {
  channelDetail: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  handleRefreshData: PropTypes.func.isRequired,
  formValueChangedCallback: PropTypes.func.isRequired,
};

export default AppVFRSettings;
