import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Paper, Tab, Tabs } from '@mui/material';
import AppLayout from '../../layout/app.layout';
import ListAppContentPages from '../../components/app-content/list-app-content-pages.component';
import ListAppContentLabels from '../../components/app-content/list-app-content-labels.component';
import ListAppContentFAQs from '../../components/app-content/list-app-content-faqs.component';
import ManageAppContentAssets from '../../components/app-content/manage-app-content-assets.component';
import ListAppContentPackages from '../../components/app-content/list-app-content-packages.component';

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const AppDefaultContentView = () => {
  const id = 0;
  const [activeTab, setActiveTab] = useState(0);
  const [tabValue, setTabValue] = useState('pages');

  useEffect(() => {
    if (tabValue === 'pages') {
      setActiveTab(0);
    } else if (tabValue === 'faqs') {
      setActiveTab(1);
    } else if (tabValue === 'labels') {
      setActiveTab(2);
    } else if (tabValue === 'assets') {
      setActiveTab(3);
    } else {
      setActiveTab(4);
    }
  }, [tabValue]);

  return (
    <AppLayout pageTitle="Channel Content Management / Default Content (Channel)">
      <Box component={Paper}>
        <Box>
          <Tabs
            value={tabValue}
            aria-label="tabs"
            indicatorColor="primary"
            onChange={(_, v) => {
              setTabValue(v);
            }}
          >
            <Tab value="pages" label="Pages" {...a11yProps(0)} />
            <Tab value="faqs" label="FAQs" {...a11yProps(1)} />
            <Tab value="labels" label="Labels" {...a11yProps(2)} />
            <Tab value="assets" label="Assets & Theme" {...a11yProps(3)} />
            <Tab value="packages" label="Packages" {...a11yProps(4)} />
          </Tabs>
        </Box>
        <Box>
          {tabValue === 'pages' && (
            <TabPanel value={activeTab} index={0}>
              <ListAppContentPages channelId={id} />
            </TabPanel>
          )}
          {tabValue === 'faqs' && (
            <TabPanel value={activeTab} index={1}>
              <ListAppContentFAQs channelId={id} />
            </TabPanel>
          )}
          {tabValue === 'labels' && (
            <TabPanel value={activeTab} index={2}>
              <ListAppContentLabels channelId={id} />
            </TabPanel>
          )}
          {tabValue === 'assets' && (
            <TabPanel value={activeTab} index={3}>
              <ManageAppContentAssets channelId={id} />
            </TabPanel>
          )}
          {tabValue === 'packages' && (
            <TabPanel value={activeTab} index={4}>
              <ListAppContentPackages channelId={id} />
            </TabPanel>
          )}
        </Box>
      </Box>
    </AppLayout>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
};

export default AppDefaultContentView;
