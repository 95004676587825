import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListTags from '../../components/advertisers/list-ad-provider-tags.component';

const TagsLibraryView = () => {
  const pageTitle = 'Advertising / Tag Library';

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListTags />
    </AppLayout>
  );
};

export default TagsLibraryView;
