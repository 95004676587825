import axios from 'axios';
import prepareRequestOptions from '../utils/admin-apis.util';

export const getAdProviders = async () => {
  const options = prepareRequestOptions(
    `/admin/ad-providers/list`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const createNewData = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/ad-providers/create',
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};
