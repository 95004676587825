import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableContainer,
  Paper,
  TextField,
  Button,
  Box,
  Checkbox,
  Typography,
  Tooltip,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import MANAGE_COLUMNS from '../../config/module-configs/assets.config';
import {
  getChannelAssetData,
  updateChannelAssetData,
} from '../../services/apps.service';
import ConfirmPopup from '../common/confirm-popup.component';

const ManageAppContentAssets = ({ channelId }) => {
  const columns = MANAGE_COLUMNS;

  const [showConfirmDailog, setShowConfirmDailog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
  } = useForm({});

  useEffect(() => {
    getChannelAssetData(channelId)
      .then((res) => {
        Object.keys(res.data).forEach((key) => {
          setValue(`${key}.value`, res.data[key]);
        });
        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, []);

  const handleSaveData = () => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    const payload = getValues();
    updateChannelAssetData(channelId, payload)
      .then(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: 'Assets & theme details updated successfully.',
        });
        setShowConfirmDailog(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setShowConfirmDailog(false);
      });
  };

  const onFormSubmit = (data) => {
    if (Object.values(data).some((item) => item.createForAllChannel)) {
      setShowConfirmDailog(true);
    } else {
      handleSaveData();
    }
  };

  return (
    <>
      {loading && <Loading />}

      {!loading && (
        <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              maxHeight: '75vh',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                flex: 1,
                overflowY: 'auto',
                padding: 2,
              }}
            >
              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell width="20%">
                        <Typography
                          variant="subtitle1"
                          sx={{ textAlign: 'center' }}
                        >
                          Label
                        </Typography>
                      </TableCell>
                      <TableCell width="70%">
                        {' '}
                        <Typography
                          variant="subtitle1"
                          sx={{ textAlign: 'center' }}
                        >
                          Value
                        </Typography>
                      </TableCell>
                      {!!channelId && (
                        <TableCell width="10%">
                          <Typography
                            variant="subtitle1"
                            sx={{ textAlign: 'center' }}
                          >
                            Update for all channels
                          </Typography>
                        </TableCell>
                      )}
                    </TableRow>
                    {columns.map((c) => (
                      <TableRow key={`col-${c.id}`}>
                        <TableCell
                          width="20%"
                          variant="head"
                          sx={{ border: 0, verticalAlign: 'center' }}
                        >
                          {c.label}
                        </TableCell>
                        <TableCell
                          width="70%"
                          variant="body"
                          sx={{ border: 0, verticalAlign: 'top' }}
                        >
                          <Controller
                            name={`${c.dataKey}.value`}
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <TextField
                                {...field}
                                label={c.label}
                                type="text"
                                error={!!errors?.[c.dataKey]?.value}
                                helperText={
                                  errors?.[c.dataKey]?.value?.message || null
                                }
                                fullWidth
                                variant="filled"
                              />
                            )}
                          />
                          {(c.dataKey === 'homePageHeroImage' ||
                            c.dataKey === 'browsePageBgImage') && (
                            <Box color="gray" fontSize="12px">
                              Note: Upload image in 16:9 aspect ratio (1920 x
                              1080 px) for better experience.
                            </Box>
                          )}
                          {c.dataKey === 'favIconImage' && (
                            <Box color="gray" fontSize="12px">
                              Note: Upload fav icon in 1:1 aspect ratio (16 x 16
                              px) for better experience.
                            </Box>
                          )}
                          {c.dataKey === 'logoImage' && (
                            <Box color="gray" fontSize="12px">
                              Note: Upload logo in 108:25 aspect ratio (216 x 50
                              px) for better experience.
                            </Box>
                          )}
                        </TableCell>
                        {!!channelId && (
                          <TableCell
                            width="10%"
                            variant="body"
                            sx={{ border: 0, verticalAlign: 'top' }}
                          >
                            <Controller
                              name={`${c.dataKey}.createForAllChannel`}
                              control={control}
                              defaultValue={false}
                              render={({ field: { onChange, value } }) => (
                                <Tooltip title="update for all channels" arrow>
                                  <Checkbox
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                    }}
                                    onChange={onChange}
                                    checked={value || false}
                                  />
                                </Tooltip>
                              )}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box
              sx={{
                padding: 2,
                borderTop: '1px solid #ddd',
                textAlign: 'center',
                backgroundColor: 'white',
              }}
            >
              <Button
                type="submit"
                form="add-edit-form"
                color="primary"
                variant="contained"
                disabled={loading}
              >
                Save
              </Button>
            </Box>
          </Box>
        </form>
      )}

      {showConfirmDailog && (
        <ConfirmPopup
          title="Update for all channels confirmation"
          message={`You have selected 'update for all channels'. Do you want to update assets & theme for all channels? You can not undo this action!`}
          onCancel={() => setShowConfirmDailog(false)}
          onSuccess={handleSaveData}
        />
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

ManageAppContentAssets.propTypes = {
  channelId: PropTypes.number.isRequired,
};

export default ManageAppContentAssets;
