import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListContentApps from '../../components/content/list-apps.component';

const ContentAppsView = () => {
  const pageTitle = 'Channel Content Management / Channels';

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListContentApps />
    </AppLayout>
  );
};

export default ContentAppsView;
