import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import {
  Box,
  List,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Collapse,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  LS_ADMIN_USER_ROLE,
  VALID_ADMIN_ROLES,
} from '../../../config/const.config';
import ROUTE_PATH from '../../../config/routes.config';
import { getLocalValue } from '../../../utils/local-cache.util';
import { GREY, PRIMARY } from '../../../theme/theme-colors';
import Iconify from '../../common/iconify.component';

const StyledNavItem = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: 'white',
  borderRadius: theme.shape.borderRadius,
}));

const StyledNavItemIcon = styled(ListItemIcon)({
  width: 24,
  height: 24,
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 24,
});

const NavItem = ({ title, path, icon, subNavItem }) => (
  <StyledNavItem
    component={RouterLink}
    to={path}
    sx={{
      '&.active': {
        color: 'white',
        bgcolor: PRIMARY.main,
        fontWeight: 'fontWeightBold',
      },
      bgcolor: subNavItem ? GREY[900] : 'inherit',
      pl: subNavItem ? 2 : 0,
    }}
  >
    <StyledNavItemIcon>{icon}</StyledNavItemIcon>
    <ListItemText disableTypography primary={title} />
  </StyledNavItem>
);

NavItem.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  subNavItem: PropTypes.bool,
};

NavItem.defaultProps = {
  subNavItem: false,
};

const CollapsibleNavSection = ({ title, icon, children }) => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <StyledNavItem onClick={handleToggle}>
        <StyledNavItemIcon>{icon}</StyledNavItemIcon>
        <ListItemText disableTypography primary={title} />
        {open ? (
          <Iconify icon="ic:round-expand-less" width={25} />
        ) : (
          <Iconify icon="ic:round-expand-more" width={25} />
        )}
      </StyledNavItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List disablePadding>{children}</List>
      </Collapse>
    </>
  );
};

CollapsibleNavSection.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  children: PropTypes.node.isRequired,
};

const NavItems = () => {
  const accessRole = getLocalValue(LS_ADMIN_USER_ROLE);

  return (
    <Box>
      <List disablePadding sx={{ p: 1 }}>
        <NavItem
          title="Home"
          path={ROUTE_PATH.DASHBOARD}
          icon={<Iconify icon="ic:round-dashboard" />}
        />
        <NavItem
          title="Realtime Apps"
          path={ROUTE_PATH.REALTIME_DASHBOARD}
          icon={<Iconify icon="tabler:activity-heartbeat" />}
        />
        <NavItem
          title="Realtime Users"
          path={ROUTE_PATH.REPORTS_LIVE_USERS}
          icon={<Iconify icon="ic:round-live-tv" />}
        />

        <CollapsibleNavSection
          title="Reports"
          icon={<Iconify icon="ic:outline-analytics" />}
        >
          <NavItem
            title="Overview"
            path={ROUTE_PATH.REPORTS_OVERVIEW}
            icon={<Iconify icon="ic:outline-analytics" />}
            subNavItem
          />
          <NavItem
            title="Migration Status"
            path={ROUTE_PATH.REPORTS_MIGRATION_STATUS}
            icon={<Iconify icon="ic:outline-analytics" />}
            subNavItem
          />
          <NavItem
            title="Migration Users"
            path={ROUTE_PATH.REPORTS_MIGRATION_USERS}
            icon={<Iconify icon="ic:outline-analytics" />}
            subNavItem
          />
          <NavItem
            title="Active Subscribers"
            path={ROUTE_PATH.REPORTS_ACTIVE_SUBSCRIBERS}
            icon={<Iconify icon="ic:outline-analytics" />}
            subNavItem
          />
          <NavItem
            title="Trial Period Subscribers"
            path={ROUTE_PATH.REPORTS_TRIAL_SUBSCRIBERS}
            icon={<Iconify icon="ic:outline-analytics" />}
            subNavItem
          />
          <NavItem
            title="Users Count"
            path={ROUTE_PATH.REPORTS_CHANNEL_ALL_USERS}
            icon={<Iconify icon="ic:outline-analytics" />}
            subNavItem
          />
        </CollapsibleNavSection>

        <CollapsibleNavSection
          title="Engagement"
          icon={<Iconify icon="ic:twotone-checklist" />}
        >
          {[VALID_ADMIN_ROLES.SUPER_ADMIN, VALID_ADMIN_ROLES.ADMIN].includes(
            accessRole
          ) && (
            <>
              <NavItem
                title="Views by App"
                path={ROUTE_PATH.CONTENT_CHANNELS}
                icon={<Iconify icon="ic:twotone-checklist" />}
                subNavItem
              />
              <NavItem
                title="Views by Video"
                path={ROUTE_PATH.CONTENT_VIDEOS}
                icon={<Iconify icon="octicon:video-16" />}
                subNavItem
              />
              <NavItem
                title="Views by Playlist"
                path={ROUTE_PATH.CONTENT_PLAYLISTS}
                icon={<Iconify icon="ic:round-playlist-add" />}
                subNavItem
              />
            </>
          )}
          <NavItem
            title="Views by Session (Video)"
            path={ROUTE_PATH.REPORTS_SESSIONS}
            icon={<Iconify icon="ic:outline-list-alt" />}
            subNavItem
          />
          <NavItem
            title="Views by Session (App)"
            path={ROUTE_PATH.REPORTS_APP_SESSIONS}
            icon={<Iconify icon="ion:analytics-sharp" />}
            subNavItem
          />
        </CollapsibleNavSection>
        {[VALID_ADMIN_ROLES.SUPER_ADMIN, VALID_ADMIN_ROLES.ADMIN].includes(
          accessRole
        ) && (
          <CollapsibleNavSection
            title="Content Management"
            icon={
              <Iconify icon="material-symbols-light:bookmark-manager-outline" />
            }
          >
            <NavItem
              title="Channels"
              path={ROUTE_PATH.CONTENT_ALL_CHANNELS}
              icon={<Iconify icon="ic:twotone-checklist" />}
              subNavItem
            />
            <NavItem
              title="Default Content (Channel)"
              path={ROUTE_PATH.CHANNEL_DEFAULT_CONTENT}
              icon={<Iconify icon="healthicons:default-outline" />}
              subNavItem
            />
          </CollapsibleNavSection>
        )}
        {[VALID_ADMIN_ROLES.SUPER_ADMIN, VALID_ADMIN_ROLES.ADMIN].includes(
          accessRole
        ) && (
          <CollapsibleNavSection
            title="Advertising"
            icon={<Iconify icon="tabler:ad" />}
          >
            <NavItem
              title="Tag Library"
              path={ROUTE_PATH.ADVERTISERS_TAG_LIBRARY}
              icon={<Iconify icon="mdi:tag-outline" />}
              subNavItem
            />
            <NavItem
              title="Assign Tags"
              path={ROUTE_PATH.ADVERTISERS_TAG_ASSIGN}
              icon={<Iconify icon="mdi:tag-plus-outline" />}
              subNavItem
            />
            <NavItem
              title="Deployed Tags"
              path={ROUTE_PATH.ADVERTISERS_TAG_DEPLOYED}
              icon={
                <Iconify icon="material-symbols-light:list-alt-check-outline" />
              }
              subNavItem
            />
          </CollapsibleNavSection>
        )}

        <CollapsibleNavSection
          title="User Management"
          icon={<Iconify icon="ci:users" />}
        >
          <NavItem
            title="All Users"
            path={ROUTE_PATH.SUBSCRIBERS}
            icon={<Iconify icon="ci:users" />}
            subNavItem
          />
          <NavItem
            title="Subscribers"
            path={ROUTE_PATH.BILLING_SUBSCRIPTIONS}
            icon={<Iconify icon="ic:outline-receipt-long" />}
            subNavItem
          />
          <NavItem
            title="Payments"
            path={ROUTE_PATH.BILLING_PAYMENTS}
            icon={<Iconify icon="ic:outline-payments" />}
            subNavItem
          />
        </CollapsibleNavSection>

        {[VALID_ADMIN_ROLES.SUPER_ADMIN, VALID_ADMIN_ROLES.ADMIN].includes(
          accessRole
        ) && (
          <CollapsibleNavSection
            title="Settings"
            icon={<Iconify icon="ic:outline-settings" />}
          >
            {accessRole === VALID_ADMIN_ROLES.SUPER_ADMIN && (
              <NavItem
                title="Admin Access"
                path={ROUTE_PATH.ADMIN_USERS}
                icon={<Iconify icon="mdi:users-check" />}
                subNavItem
              />
            )}
            <NavItem
              title="Coupon Codes"
              path={ROUTE_PATH.COUPONS_CONFIG}
              icon={<Iconify icon="mdi:coupon" />}
              subNavItem
            />
            <NavItem
              title="Email Settings"
              path={ROUTE_PATH.SMTP_SETTINGS}
              icon={<Iconify icon="ic:outline-mail" />}
              subNavItem
            />
          </CollapsibleNavSection>
        )}
      </List>
    </Box>
  );
};

export default NavItems;
