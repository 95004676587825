// Define commonly used constants
export const LS_ADMIN_USER_AUTH_TOKEN = '__pnm_admin_t';
export const LS_ADMIN_USER_NAME = '__pnm_admin_n';
export const LS_ADMIN_USER_EMAIL = '__pnm_admin_e';
export const LS_ADMIN_USER_ROLE = '__pnm_admin_r';

export const VALID_ADMIN_ROLES = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  CONTENT_OWNER: 'CONTENT_OWNER',
};

export const STATUS_LABELS = {
  0: 'Inactive',
  1: 'Active',
  2: 'Deleted',
  5: 'Requested Delete',
  11: 'Pending Verification',
  12: 'Cancellation Applied',
  13: 'Cancelled',
  14: 'Expired',
};

export const MIGRATION_STATUS_LABELS = {
  0: 'None',
  1: 'Imported',
  2: 'Login Attempted',
  3: 'Password Reset',
  4: 'Account Synced',
  5: 'Migrated',
};

export const BRD_STATUS_LABELS = {
  0: 'No',
  1: 'Yes',
};

export const STATUS_COLORS = {
  0: 'error',
  1: 'success',
  2: 'error',
  5: 'warning',
  11: 'warning',
  12: 'warning',
  13: 'error',
  14: 'error',
};

export const MIGRATION_STATUS_COLORS = {
  0: 'error',
  1: 'primary',
  2: 'primary',
  3: 'info',
  4: 'warning',
  5: 'success',
};

export const STATUS_ALL_VALUE = 99999;

export const ACTIONS = {
  CREATE: {
    value: 'create',
    label: 'Create New',
  },
  EDIT: {
    value: 'update',
    label: 'Edit',
  },
  VIEW: {
    value: 'view',
    label: 'View',
  },
  DELETE: {
    value: 'delete',
    label: 'Delete',
  },
  CHANGE_PASSWORD: {
    value: 'change_password',
    label: 'Change Password',
  },
  ASSIGN_CHANNELS: {
    value: 'assign_channels',
    label: 'Assign Channels',
  },
  MANAGE_CONTENT: {
    value: 'manage_content',
    label: 'Manage Web Content',
  },
  COPY_CHANNEL: {
    value: 'duplicate_channel',
    label: 'Duplicate Channel',
  },
};

export const APP_TYPES = {
  AVOD_TYPE: 'AVOD Only',
  SVOD_TYPE: 'SVOD Only',
  TVOD_TYPE: 'TVOD Only',
  AVOD_SVOD_TYPE: 'AVOD & SVOD',
  AVOD_TVOD_TYPE: 'AVOD & TVOD',
  SVOD_TVOD_TYPE: 'SVOD & TVOD',
  AVOD_SVOD_TVOD_TYPE: 'AVOD & SVOD & TVOD',
};

export const IAP_SETTINGS = {
  ROKU_BB: 'Bach Entertainment (BadBen)',
  ROKU_POV: 'POV Horror LLC',
  ROKU_WICK_CON: 'Wicked Content',
  ROKU_HB: 'Hexabrain',
  FIRETV_BB: 'Bach Entertainment (BadBen)',
  FIRETV_POV: 'POV Horror LLC',
  FIRETV_PNM: 'PlayNowMedia LLC',
  FIRETV_HB: 'Hexabrain',
  ANDROID_THUNDER: 'Thunder Road Inc',
  ANDROID_PNM: 'PlayNowMedia LLC',
  ANDROID_HB: 'Hexabrain',
  APPLE_THUNDER: 'Thunder Road Inc',
  APPLE_PNM: 'PlayNowMedia LLC',
  APPLE_HB: 'Hexabrain',
  WEB_BB: 'Bach Entertainment (BadBen)',
  WEB_BB_TEST: 'Bach Entertainment (BadBen) [Test]',
  WEB_POV: 'POV Horror LLC',
  WEB_POV_TEST: 'POV Horror LLC [Test]',
  WEB_HB_TEST: 'Hexabrain [Test]',
};
